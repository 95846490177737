import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
// styles
import styles from './traders-alloy.module.css';
import itemStyles from '../components/item/cases.module.css';
// components
import Layout from '../components/item/layout';
import Technology from 'components/technology';
import Team from '../components/team';
// other
import { meta } from '../../../metaData';

const TradersAlloy = () => {
  const data = useStaticQuery(graphql`
    query {
      screen1: file(relativePath: { eq: "traders-alloy/screen1.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen2: file(relativePath: { eq: "traders-alloy/screen2.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screen3: file(relativePath: { eq: "traders-alloy/screen3.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      picture: file(relativePath: { eq: "traders-alloy/picture.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1530) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ci_cd_workflow: file(relativePath: { eq: "traders-alloy/ci-cd-workflow.svg" }) {
        publicURL
      }
      workflow: file(relativePath: { eq: "traders-alloy/workflow.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <Layout
      score={['Fintech', 'Sweden', '2020', 'Ongoing']}
      navBtn="#14181A"
      className={styles.header}
      contentClassName={styles.content}
      headerTitle="Traders Alloy - CI/CD pipeline for a Fintech startup"
      subTitle="Switching from manual to automated deployment gave Traders Alloy a smoother path to production"
      metaData={meta.tradersAlloy}
      isRed={false}
    >
      <div className={itemStyles.inner}>
        <h2 className={itemStyles.title}>The situation</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Releasing software and features can be time-consuming if you do it manually. And if you
          don’t get it right, you risk losing customers and being forced back to the drawing board.
          But there’s a way around all of those issues — continuous integration and continuous
          deployment (CI/CD). Our client, the founder of Traders Alloy, a web-based financial
          decision support tool, was bogged down with a slow, manual deployment cycle and
          desperately needed to automate software releases to make the company more agile. Traders
          Alloy’s previous vendor was unable to get the CI/CD pipeline up and running, and our main
          task was to set it up and show the team how to use it to test and deliver software
          predictably.
        </p>
      </div>
      <div className={itemStyles.inner}>
        <h3 className={styles.subTitle}>Our Services</h3>
        <ul className={clsx(itemStyles.bullets, styles.bullets)}>
          <li className={styles.listItem} key="DevOps">
            DevOps
          </li>
        </ul>
        <h3 className={styles.subTitle}>Technology</h3>
        <Technology list={['digital_ocean', 'jenkins', 'blue_ocean']} />
        <h2 className={clsx(itemStyles.title, styles.innerTitle)}>Team composition</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          After a tech and processes audit, we assigned a senior DevOps engineer to implement the
          CI/CD workflow.
        </p>
        <div className={styles.teamContainer}>
          <Team
            color="#4274a4"
            list={[{ count: 1, title: 'DevOps engineer' }]}
            containerClassName={styles.teamItem}
          />
        </div>
        <h2 className={clsx(itemStyles.title, styles.innerTitle)}>Key challenges</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          In the hyper-competitive digital world, businesses that are slow to market risk failure.
          Traders Alloy needed to deliver features faster, make their development processes agile
          and automate testing in the cloud.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          The Traders Allou website ran on a Digital Ocean cloud server, and they also had a
          physical server on-premises. So, one of the biggest challenges we had to tackle was to use
          the available resources to the fullest, without overloading any of the servers.
        </p>
        <h3 className={styles.subTitle}>CI/CD workflow</h3>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Continuous integration and continuous deployment save companies precious time and money.
          Developers can make minor code changes, and errors can be found and solved faster. Plus,
          it’s all automated, reducing deployment time and the chance that someone could make a
          mistake, compared to doing it manually like Traders Alloy was doing.
        </p>
        <picture>
          <source
            srcSet={data.ci_cd_workflow.publicURL}
            media="(max-width: 768px)"
            type="image/svg+xml"
          />
          <source
            srcSet={data.workflow.publicURL}
            media="(min-width: 769px)"
            type="image/svg+xml"
          />
          <img src={data.workflow.publicURL} className={styles.workflowImg} alt="ci cd workflow" />
        </picture>
        <h3 className={styles.subTitle}>Automated testing</h3>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Automated testing, and automation in general, frees up time that developers would
          otherwise spend running tests and making sure the features work before delivery. The
          Traders Alloy team wanted to run tests and show test results in the Jenkins UI.
        </p>
      </div>
      <ul className={styles.imagesContainer}>
        <li className={styles.imageItem} key="screen1">
          <Img fluid={data.screen1.childImageSharp.fluid} alt="application screenshot" />
        </li>
        <li className={styles.imageItem} key="screen2">
          <Img fluid={data.screen2.childImageSharp.fluid} alt="application screenshot" />
        </li>
        <li className={styles.imageItem} key="screen3">
          <Img fluid={data.screen3.childImageSharp.fluid} alt="application screenshot" />
        </li>
      </ul>
      <div className={itemStyles.inner}>
        <h2 className={clsx(itemStyles.title, styles.solution)}>Delivered solution</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          We looked at and proposed various tools but stopped at Jenkins because it’s open-source,
          reliable, and easy to configure and manage. We installed it on the client’s test server
          and used it to implement CI/CD for backend and frontend development, covering Traders
          Alloy's entire software development lifecycle.
        </p>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          After launching the CI/CD pipeline, our DevOps engineer showed the Traders Alloy team how
          to self-manage, test, and deploy. Once we gathered feedback from the team, we learned that
          Slack notifications for deployments and testing would greatly benefit and streamline their
          processes by adding transparency.
        </p>
        <h2 className={itemStyles.title}>The results</h2>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          With a CI/CD workflow and automated testing, the team can now:
        </p>
        <ul className={clsx(itemStyles.bullets, styles.bulletsColor)}>
          <li>Run tests and display test results in Jenkins</li>
          <li>Deliver features to multiple environments</li>
          <li>Get notifications about deployments and tests in Slack</li>
          <li>Manage user roles: who can deploy, read test reports, etc.</li>
        </ul>
        <p className={clsx(itemStyles.paragraph, styles.skimpy)}>
          Today, Traders Alloy is fully autonomous, with an occasional helping hand from our DevOps
          engineer. They deploy several times a day without skipping a beat and smash any bugs they
          encounter with autotests.
        </p>
        <div className={styles.benefits}>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>5x</dt>
            <dd className={styles.description}>Faster deployment</dd>
          </dl>
          <dl className={styles.benefits__item}>
            <dt className={styles.point}>66.6%</dt>
            <dd className={styles.description}>More features released</dd>
          </dl>
        </div>
      </div>
      <Img
        fluid={data.picture.childImageSharp.fluid}
        className={styles.lastImage}
        alt="laptop with an open application"
      />
    </Layout>
  );
};
export default TradersAlloy;
